import React, { useState } from 'react';

export interface GlobalContextType {
    theme?: 'dark' | 'light';
    setTheme: (theme: 'dark' | 'light' | undefined) => void;
}

export const GlobalContext = React.createContext<GlobalContextType>({
    setTheme: () => {},
});

const GlobalContextProvider = (props: { children: React.ReactChildren }) => {
    const [theme, setTheme] = useState<'dark' | 'light'>();

    const globalContext: GlobalContextType = {
        theme,
        setTheme,
    };

    return <GlobalContext.Provider value={globalContext}>{props.children}</GlobalContext.Provider>;
};

// const wrapWithProvider = ({ element }: { element: React.ReactChildren }) => (
//     <GlobalContextProvider>{element}</GlobalContextProvider>
// );

export default GlobalContextProvider;
