import React from 'react';
import { Code } from './src/components/Code';

export const components = {
    'p.inlineCode': props => <code {...props} style={{ backgroundColor: 'lightgrey' }}></code>,
    pre: ({ children: { props } }) => {
        if (props.mdxType === 'code') {
            return (
                <Code
                    codeString={props.children.trim()}
                    language={props.className && props.className.replace('language-', '')}
                    {...props}
                />
            );
        }
    },
    img: props => <img {...props} style={{ ...props.style, borderRadius: '6px' }} />,
};
