import { MDXProvider } from '@mdx-js/react';
import React from 'react';
import { components } from './root-wrapper';
import GlobalContextProvider from './src/context/GlobalContextProvider';
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => {
    return (
        <GlobalContextProvider>
            <MDXProvider components={components}>{element}</MDXProvider>
        </GlobalContextProvider>
    );
};
